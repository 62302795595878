import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import '../src/app/core/extensions';
import { browserTracingIntegration, replayIntegration } from '@sentry/angular-ivy';

if (!location.origin.includes('localhost')) {
  Sentry.init({
    dsn: environment.dsn,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    integrations: [
      browserTracingIntegration(),
      replayIntegration()
    ],
    tracePropagationTargets: [environment.appUrl, environment.apiUrl],
    release: environment.version.replace('v', 'ver-'),
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
