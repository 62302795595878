export enum MandatorDictionaries {
  Currencies = 'Currencies',
  TabVoucherNames = 'TabVoucherNames',
  TabCardsNames = 'TabCardsNames',
  TabPriceNames = 'TabPriceNames',
  CardTypes = 'CardTypes',
  Kiosks = 'Kiosks',
  KioskProductGroup = 'KioskProductGroup',
  KioskPriceCategories = 'KioskPriceCategories',
}
