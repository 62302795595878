import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../../auth/core/services/AuthService';
import { LanguageStoreService } from '../../language/core/services/LanguageStoreService';
import { NavigationService } from '@cinetixx/cinetixx-ui';
import { IdentityService } from '../services/IdentityService';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

  private _isRefreshing = false;
  private readonly _refreshToken$ = new BehaviorSubject<string>(null);
  private readonly _endpointsWithoutToken = [
    '/employees/login',
    '/employees/refresh-token',
    '/snippets/languages/all',
    '/snippets/all',
    '/employees/login-with-token',
    '/cinetixx-screener',
    '/cinetixx-movies'
  ];
  private readonly _endpointsWithoutLang = [];

  constructor(
    private readonly _identityService: IdentityService,
    private readonly _authService: AuthService,
    private readonly _navigationService: NavigationService,
    private readonly _langService: LanguageStoreService
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      'Accept': 'application/json'
    };

    if (!this._endpointsWithoutLang.find(endpoint => req.url.includes(endpoint))) {
      const currentLang = this._langService.language?.token;

      if(currentLang) {
        headersConfig['X-Lang'] = currentLang;
      }
    }

    if (!this._endpointsWithoutToken.find(endpoint => req.url.includes(endpoint))) {
      const identity = this._identityService.identity;

      if (identity) {
        const { refreshToken, accessToken } = identity;
        headersConfig['Authorization'] = `Bearer ${ req.url.includes('refresh-token') ? refreshToken : accessToken }`;
      }

      const request = req.clone({
        setHeaders: headersConfig
      });

      return next.handle(request).pipe(
        catchError(error => {
          if (req.url.includes('refresh-token')) {
            this._authService.logout();

            return throwError(error);
          } else {
            return error.status === 401 ? this.handle401Error(request, next) : throwError(error);
          }
        })
      );
    } else {
      const request = req.clone({
        setHeaders: headersConfig
      });
      return next.handle(request)
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this._isRefreshing) {
      this._isRefreshing = true;
      this._refreshToken$.next(null);

      return this._authService.refreshToken().pipe(
        switchMap(({ accessToken }) => {
          this._isRefreshing = false;
          this._identityService.identity = {
            ...this._identityService.identity,
            accessToken
          };

          this._refreshToken$.next(accessToken);

          return next.handle(this.appendToken(request, accessToken));
        }),
        catchError((error) => {
          this._navigationService.navigateByUrl(`/auth/login`);
          return throwError(error);
        })
      );
    } else {
      return this._refreshToken$.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => next.handle(this.appendToken(request, token)))
      );
    }
  }

  private appendToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${ token }`
      }
    });
  }
}
